import classNames from "classnames"
import React from "react"

import { SubMenuBlockProps } from "./SubMenuBlock"
import SubMenus from "./SubMenus"
import Grid from "../../elements/V2/Grid"

import Link from "@components/elements/Link"

export interface MainLink {
  label: string
  link?: string | Storyblok.Link
  subMenuBlocks?: SubMenuBlockProps[]
}

type Props = {
  mainLink: MainLink
  className?: string
  mode: "light" | "dark"
  scrolled: boolean
  isMenuActive: boolean
  handleClick: () => void
  handleHover: () => void
  isSubMenuActive: boolean
  isMenuOpenOnMobile: boolean
}

const NavHeading = ({
  mainLink,
  className,
  mode,
  scrolled,
  isMenuActive,
  handleClick,
  handleHover,
  isSubMenuActive,
  isMenuOpenOnMobile,
}: Props) => {
  const commonClasses = classNames(
    className,
    {
      "px-gutter-xs-v2 sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2":
        isMenuOpenOnMobile,
    },
    {
      "text-black-v2 lg-v2:text-white-v2":
        mode === "dark" && !scrolled && !isMenuActive,
    },
    {
      "text-black-v2": (mode === "light" && scrolled) || isMenuActive,
    }
  )

  return (
    <React.Fragment>
      {mainLink.link ? (
        <Link
          className={commonClasses}
          link={mainLink.link}
          onClick={() => handleClick()}
          onMouseEnter={() => handleHover()}
          onFocus={() => handleHover()}
        >
          {mainLink.label}
          <img
            src="/icons/V2/chevron-down.svg"
            alt={`Open ${mainLink.label}`}
            width={12}
            height={9}
            className={classNames(
              "transform transition-transform duration-200 ease-in-out lg-v2:hidden",
              {
                "rotate-180": isSubMenuActive,
              },
              { hidden: !mainLink.subMenuBlocks?.length }
            )}
          />
        </Link>
      ) : (
        <p
          className={commonClasses}
          onClick={() => handleClick()}
          onMouseEnter={() => handleHover()}
          onFocus={() => handleHover()}
        >
          {mainLink.label}
          <img
            src="/icons/V2/chevron-down.svg"
            alt={`Open ${mainLink.label}`}
            width={12}
            height={9}
            className={classNames(
              "transform transition-transform duration-200 ease-in-out lg-v2:hidden",
              {
                "rotate-180": isSubMenuActive,
              },
              { hidden: !mainLink.subMenuBlocks?.length }
            )}
          />
        </p>
      )}

      {isMenuOpenOnMobile && (
        <div
          className={classNames(
            "border-b border-b-black-v2 border-opacity-10 lg-v2:border-b-transparent",
            {
              "transition-all duration-300 ease-in-out": isMenuOpenOnMobile,
            },
            {
              "mx-gutter-xs-v2 sm-v2:mx-gutter-sm-v2 md-v2:mx-gutter-md-v2":
                isSubMenuActive && isMenuOpenOnMobile,
            }
          )}
        />
      )}

      {mainLink.subMenuBlocks?.length ? (
        <div
          className={classNames(
            "left-0 z-50 max-h-0 w-full overflow-hidden transition-all duration-200 ease-in-out lg-v2:pointer-events-none lg-v2:absolute lg-v2:mt-[22px] lg-v2:max-h-max lg-v2:px-gutter-lg-v2 lg-v2:opacity-0 xl-v2:px-gutter-xl-v2",
            {
              "max-h-[800px] pb-24-v2 lg-v2:pointer-events-auto lg-v2:opacity-100":
                isSubMenuActive,
            },
            {
              "bg-white-v2 lg-v2:shadow-[0_16px_16px_-8px] lg-v2:shadow-charcoal-v2/20":
                scrolled || isMenuActive,
            }
          )}
        >
          <div
            className={
              "rounded-sm-v2 bg-white-v2 px-gutter-xs-v2 py-20-v2 transition-height sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2 md-v2:py-24-v2 lg-v2:-ml-32-v2 lg-v2:w-[calc(100%_+_64px)] lg-v2:bg-transparent lg-v2:px-32-v2 lg-v2:py-64-v2"
            }
          >
            <Grid
              className={classNames(
                "transition-all duration-300",
                isSubMenuActive
                  ? "translate-y-0 opacity-100"
                  : "translate-y-1/3 opacity-0 lg-v2:translate-y-1/2"
              )}
            >
              <SubMenus subMenus={mainLink.subMenuBlocks} />
            </Grid>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default NavHeading
