import classNames from "classnames"
import React from "react"

import Link from "../../../components/elements/Link"
import { Color } from "../../../constants/V2/color"
import TextButton from "../../elements/V2/Buttons/TextButton"
import ArrowIcon from "../../elements/V2/Icons/ArrowIcon"
import Typography from "../../elements/V2/Typography"

interface DescriptionSubMenu {
  type: "description"
  title: string
  description: string
  link?: string
  linkText?: string
}

interface LinksSubMenu {
  type: "links"
  title: string
  links: {
    label: string
    link: string | Storyblok.Link
    badgeText?: string
    badgeColor?: Color.Pink | Color.LightBlue
  }[]
}

export type SubMenuBlockProps = DescriptionSubMenu | LinksSubMenu

const SubMenuBlock = (props: SubMenuBlockProps) => {
  return (
    <div
      className={
        props.type === "links" ? "col-span-4 md-v2:col-span-3" : "col-span-1"
      }
    >
      <Typography
        color={Color.Charcoal}
        font="grotesk"
        weight={props.type === "description" ? "medium" : "book"}
        size={props.type === "description" ? "lead-lg" : "body-md"}
        className={
          props.type === "description"
            ? "mb-16-v2 mt-12-v2 md-v2:mt-0"
            : "mb-16-v2"
        }
        text={props.title}
      />

      {props.type === "description" ? (
        <>
          <Typography
            color={Color.Charcoal}
            font="grotesk"
            weight="book"
            size="body-md"
            text={props.description}
          />
          {props.linkText && props.link ? (
            <TextButton
              className="mb-20-v2 mt-20-v2 md-v2:mb-0 md-v2:mt-32-v2"
              color={Color.Blue}
              text={props.linkText}
              linkUrl={props.link}
              style="icon"
              icon={<ArrowIcon />}
            />
          ) : undefined}
        </>
      ) : null}

      {props.type === "links" ? (
        <div className="flex flex-col gap-[12px] pl-20-v2 md-v2:pl-0">
          {props.links.map((link, index) => {
            const isBadge = !!(link.badgeText && link.badgeColor)
            return (
              <Link
                key={`link-${index}`}
                link={link.link}
                className={classNames(
                  "group flex w-fit items-center gap-8-v2",
                  {
                    "-ml-10-v2 rounded-20-v2 p-[5px] pl-10-v2 duration-200":
                      isBadge,
                  },
                  {
                    [link.badgeColor === Color.LightBlue
                      ? "bg-light-blue-v2 hover:bg-blue-v2"
                      : "bg-pink-v2 hover:bg-orange-v2"]: isBadge,
                  }
                )}
              >
                <Typography
                  color={Color.Charcoal}
                  font="grotesk"
                  weight="medium"
                  size="body-md"
                  text={link.label}
                  className={classNames(
                    "pt-[2px] duration-200 ease-in-out",
                    isBadge
                      ? "group-hover:text-white-v2"
                      : "group-hover:text-blue-v2"
                  )}
                />
                {isBadge && (
                  <div className="rounded-20-v2 bg-white-v2 px-[7px] pt-[2px]">
                    <Typography
                      text={link.badgeText as string}
                      weight="medium"
                      font="grotesk"
                      color={
                        link.badgeColor === Color.LightBlue
                          ? Color.Blue
                          : Color.Orange
                      }
                      size="subscript-md"
                    />
                  </div>
                )}
              </Link>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default SubMenuBlock
